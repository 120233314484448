import React, {useEffect} from 'react';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {SectionNotification, SectionNotificationType} from 'wix-ui-tpa';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import s from './MinimumOrderAmount.scss';
import {ContinueShoppingBiOrigin} from '../../../../../common/constants';
import {SPECS} from '../../../../../domain/specs';

export enum MinimumOrderAmountDataHook {
  Notification = 'MinimumOrderAmountDataHook.Notification',
  ContinueShopping = 'MinimumOrderAmountDataHook.ContinueShopping',
  NotificationText = 'MinimumOrderAmountDataHook.NotificationText',
}

export const MinimumOrderAmount = () => {
  const {continueShopping} = useControllerProps().navigationStore;
  const {minimumOrderAmount} = useControllerProps().cartStore.cart;
  const {sendMinimumOrderMessageShownBi, shouldFixCurrencyEscapingInMinimumOrderAmountMessage} =
    useControllerProps().cartStore;
  const {isCheckoutButtonDisabled} = useControllerProps().navigationStore;
  const {t} = useTranslation();
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  useEffect(() => {
    sendMinimumOrderMessageShownBi(!isCheckoutButtonDisabled);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={/* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.notification : s.notificationViewport}>
      <SectionNotification type={SectionNotificationType.alert} data-hook={MinimumOrderAmountDataHook.Notification}>
        <SectionNotification.Icon icon={<Alert className={s.icon} />} />
        <SectionNotification.Text>
          <span data-hook={MinimumOrderAmountDataHook.NotificationText}>
            {t('cart.note.minimumOrder.text', {
              minAmount: minimumOrderAmount.formattedConvertedValue,
              additionalAmount: minimumOrderAmount.formattedConvertedDiff,
              ...(shouldFixCurrencyEscapingInMinimumOrderAmountMessage ? {interpolation: {escapeValue: false}} : {}),
            })}
          </span>
          <button
            data-hook={MinimumOrderAmountDataHook.ContinueShopping}
            className={s.link}
            type="button"
            onClick={() => continueShopping(ContinueShoppingBiOrigin.minimumOrder)}>
            {t('cart.note.minimumOrder.link')}
          </button>
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};
